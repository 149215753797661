import Api from './Api';

export default {
    getAllUsers(){
        return Api().get("user/get_all_user");
    },
    getAllRoom(){
        return Api().get("room/get_all_room");
    },
    userBlock(id){
        return Api().post(`user/block_user/${id}`)
    },
    userUnBlock(id){
        return Api().post(`user/unblock_user/${id}`)
    },
    getAllRooms() {
        return Api().get("room/get_all_room");
    },
    getAllFeedback() {
        return Api().get("feedback/user_feed_back");
    },
    getTodaysUsers() { 
        return Api().get("user/get_today_login_user");
    },
   getAdminData(){
    return Api().post("admin/admin_id");
   },
   updateAdminData(data){
    return Api().post("admin/updateAdmin", (data));
   }
}
