import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as BiUserCircle from 'react-icons/bi';
import * as FiLogIn from 'react-icons/fi';
import * as SiGooglemeet from 'react-icons/si';

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Users',
    path: '/users',
    icon: <BiUserCircle.BiUserCircle />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title:'Meets',
    path:'/',
    icon : <SiGooglemeet.SiGooglemeet/>,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav:[
      {
        title: 'Today', 
        path: '/todays_meets',
      },
      {
        title: 'Year',
        path: '/year_meets',
      }
    ]
  },
  {
    title: 'Logins',
    path: '/',
    icon: <FiLogIn.FiLogIn />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Today', 
        path: '/today_logins',
      },
      {
        title: 'Month',
        path: '/month_logins',
      },
      {
        title: 'Year',
        path: '/year_logins',
      }
    ]
  },
  {
    title: 'Feedback',
    path: '/feedback',
    icon: <IoIcons.IoMdHelpCircle />
  }
];