/* eslint-disable no-lone-blocks */
import React,{useState,useEffect} from "react";
import ApiService from "../../services/ApiService";
import "./dashboard.css";
import Chart from '../chart/Chart';
import FeaturedInfo from "../featuredInfo/FeaturedInfo";
//import RoomAnalytics from "../roomAnalytics/RoomAnalytics"

const Dashboard = () => {
  const user = localStorage.getItem("token");
  // checking if user is logged in or not and redirecting to login page if not logged in and trying to access other pages except login page and dashboard page
  if (!user) {
    window.location = "/login";
  }
  const [data,setData] = useState([]);
  useEffect(()=>{
    ApiService.getAllUsers().then((res)=> setData(res.data.data))
  })
  const activeUser = data.filter((item) => (item.isBlocked=== false && item.userLogout === false))

  const logoutUser = data.filter((item) => (item.userLogout === true))
  const blockedUser = data.filter((item) => (item.isBlocked=== true ))
  const inactiveUser = logoutUser.length + blockedUser.length

  return (
    <div className="dashboard">
      <FeaturedInfo data={data} count1={activeUser.length} inactiveUser={inactiveUser} logoutUser={logoutUser} blockedUser={blockedUser} />
      <Chart data={data} title="User Analytics" grid dataKey="Active User"/>
    </div>
  );
};

export default Dashboard;
