import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ApiService from "../../services/ApiService";
import { MdDeleteOutline } from "react-icons/md";
import Modal from "@mui/material/Modal";
import axios from "axios";
import "./rooms.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const YearsMeet = () => {
  const [room, setRoom] = useState([]);
  const [open, setOpen] = React.useState("");
  const handleOpen = (id) => setOpen(id);
  const handleClose = () => setOpen("");

  useEffect(() => {
    ApiService.getAllRoom().then((res) => setRoom(res.data.room));
  });
  const handleDelete = async (id) => {
    setRoom(room.filter((item) => item._id !== id));
    const url = `https://logsign.herokuapp.com/room/delete_room/${id}`;
    await axios.post(url);
  };
  const timeDiffer = (rowData) => {
    const createdTime = rowData.meetTime;
    if(!rowData.endTime){
      return {hour:"0", minutes:"0"};
    }
    const endTimeSplit =  rowData.endTime?rowData.endTime.split(" ")[1]:"00:00";
    const hours = endTimeSplit.split(":")[0];
    const minutes = endTimeSplit.split(":")[1];

    const hoursCreated = createdTime.split(":")[0];
    const minutesCreated = createdTime.split(":")[1];

    const hoursDiff = hours - hoursCreated;
    const minutesDiff = minutes - minutesCreated;
    var m, h;
    if (minutesDiff < 0) {
      m = 60 + minutesDiff;
      h = hoursDiff - 1;
    }
    if (hoursDiff < 0) {
      h = 24 + hoursDiff;
    }

    const timeDiff = {
      hours: h,
      minutes: m,
    };
    return timeDiff;
  };
  // timeDiffer();
  const columns = [
    {
      field: "rowIndex",
      headerName: "S.No",
      width: 80,
      sortable: true,
    },
    {
      field: "name",
      headerName: "CreatedBy",
      width: 160,
      valueGetter: (params) => params.row.createdBy,
    },
    {
      field: "meet_id",
      headerName: "MeetId",
      width: 150,
      renderCell: (params) => {
        console.log("Row", params.row);
        const columns2 = [
          {
            field: "user_name",
            headerName: "Participants",
            width: 180,
            valueGetter: (params) => params.row.user_name,
          },
          {
            field: "email",
            headerName: "Email",
            width: 180,
          },
          {
            field: "joinTime",
            headerName: "JoiningTime",
            width: 180,
          },
        ];
        const rows2 = params.row.participants.map((item, index) => {
          return {
            ...item,
            id: item.userId,
            rowIndex: index + 1,
          };
        });

        return (
          <>
            <Typography
              variant="body1"
              onClick={() => handleOpen(params.row.meet_Id)}
            >
              {params.row.meet_Id}
            </Typography>
            <Modal
              open={open === params.row.meet_Id}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Room Name {params.row.meet_Id}
                </Typography>
                <Box sx={{ height: "800px", width: "100%" }}>
                  <DataGrid
                    rows={rows2}
                    columns={columns2}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                  />
                </Box>
              </Box>
            </Modal>
          </>
        );
      },
    },
    {
      field: "meetTime",
      headerName: "CreatedTime",
      type: "String",
      width: 120,
      editable: true,
    },
    {
      field: "meetDate",
      headerName: "CreatedDate",
      width: 150,
    },
    {
      field: "endTime",
      headerName: "Duration",
      type: "String",
      width: 150,
      valueGetter: (params) =>
        params.row.endTime.hours > 0
          ? params.row.endTime.hours + " hrs,"
          : " " + params.row.endTime.minutes + " mins",
    },
    {
      field: "participants",
      headerName: "Participants",
      width: 100,
      editable: true,
      valueGetter: (params) => params.row.participants.length,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <MdDeleteOutline
              className="roomListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  const rows = room.map((row, index) => ({
    ...row,
    id: row._id,
    rowIndex: index + 1,
    endTime: timeDiffer(row),
    // fullName: row.first_name + ' ' + row.lastName,
    // status: row.userLogout === true? 0 :row.isBlocked === true? 1 : 3
  }));
  return (
    <div className="rooms">
      {" "}
      <Box sx={{ height: "800px", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </div>
  );
};

export default YearsMeet;
