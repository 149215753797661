import { style } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ApiService from "../../../services/ApiService";

import "./updateUser.css";
const UpdateUser = () => {
  // get data from the server
  const [user, setUser] = useState({
    firstName:"",
    lastName:"",
    email:"",
    oldPassword:"",
    newPassword:""
  });
  const formData = new FormData();

  const [err, setErr] = useState("");
  const [confPassword, setConfPassword] = useState("");

  useEffect(() => {
    ApiService.getAdminData().then((res) => setUser({ ...res.data.setting.data }));
  }, []);
  const handleUpdate =async (e) => {
    e.preventDefault();
    if (user.newPassword ) {
      if (user.newPassword !== confPassword) {
        alert("Passwords do not match");
        return;
      }
      // password validation regex it must contain at least 8 characters, one uppercase letter, one lowercase letter and one number and special character
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!passwordRegex.test(user.newPassword)) {
      alert(
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter and one number"
      );
      return;
    }
    }
   
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!emailRegex.test(user.email)) {
      alert("Email is not valid");
      return;
    }
    // send the data to the server
    try{
      formData.append("firstName", user.firstName)
      formData.append("lastName", user.lastName)
      formData.append("email", user.email);
      formData.append("oldPassword", user.oldPassword);
      formData.append("newPassword", user.newPassword);
      // console.log(formData);
      const res = await ApiService.updateAdminData(formData);
     
      if(res.status >= 200 && res.status < 300){
        alert("Updated successfully")
      
        setUser({
          firstName:"",
          lastName:"",
          email:"",
          oldPassword:"",
          newPassword:""
        });
        window.location = "/";
      }
      if(res.status >= 400 && res.status < 500){
        alert(res.message)
        setUser({
          firstName:"",
          lastName:"",
          email:"",
          oldPassword:"",
          newPassword:""
        });
      }
    }
    catch(err){
      alert(err.message)
      setUser({
        firstName:"",
        lastName:"",
        email:"",
        oldPassword:"",
        newPassword:""
      });
    }
      
  };
  const showPassword = () => {
    const password = document.getElementById("password");
    const confpassword = document.getElementById("confpassword");
    const oldPassword = document.getElementById("oldPassword");
    if (password.type === "password" && confpassword.type === "password" && oldPassword.type === "password") {
      password.type = "text";
      confpassword.type = "text";
      oldPassword.type = "text";
    } else {
      password.type = "password";
      confpassword.type = "password";
      oldPassword.type = "password";
    }
  };


  return (
    <div className="upadateContainer">
      <div id="form" className="form-main" autoComplete="off">
        <h3
          className="form__title"
        >
          Update form
        </h3>
        <div className="form-group">
          <label for="firstName">First Name:</label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            value={user.firstName ? user.firstName : ""}
            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label for="lastName">Last Name:</label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            value={user.lastName ? user.lastName : ""}
            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label for="email">Email:</label>
          <input
            type="email"
            name="email"
            id="email"
            value={user.email ? user.email : ""}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label for="oldpassword"> Old Password:</label>
          <input
            type="password"
            name="password"
            id="oldPassword"
            onChange={(e) => setUser({ ...user, oldPassword: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label for="password"> Password:</label>
          <input
            type="password"
            name="password"
            id="password"
            onChange={(e) => setUser({ ...user, newPassword: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label for="password"> Re-enter Password:</label>
          <input
            type="password"
            name="password"
            id="confpassword"
            onChange={(e) => setConfPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group" style={{
          display: "flex",
          alignItems: "center",
        }}>
        <input type="checkbox" onClick={showPassword} />
        <label for="showPassword">Show Password</label>
        </div>
        <button className="btn" onClick={handleUpdate}>
          Update
        </button>
      </div>
    </div>
  );
};
// Style for the form-group
export default UpdateUser;
