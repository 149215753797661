import React from 'react'
import './Header.css'
import LeftBar from './leftBar/LeftBar'
import TopBar from './TopBar'

const Header = () => {
  return (
    <>
       <TopBar/>
       <LeftBar/>
    </>
  )
}

export default Header