import React from "react";
import styled from "styled-components";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";

const SidebarNav = styled.nav`
  background: rgb(43, 52, 69);
  width: 15%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
`;


const Header = styled.div`
  color: white;
  align-items: center;
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const LeftBar = () => {

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <SidebarNav>
          <SidebarWrap>
            <Header>
            </Header>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default LeftBar;
