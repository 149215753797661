import React, { useState,useEffect } from "react";
import Box from "@mui/material/Box";
import {   DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector, } from '@mui/x-data-grid';
import Pagination from "@mui/material/Pagination";
import ApiService from "../../services/ApiService";
import "./year.css";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
     color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const columns = [
  {
    field:"rowIndex",
    headerName:"S.No",
    width:100,
    sortable:true,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.first_name || ""} ${params.row.last_name || ""}`,
  },
  { field: "email", headerName: "Email", width: 180 },
  { field: "loginTime", headerName: "Login Time", width: 180 },
  {field:"loginDate",headerName:"Login Date",width:180}
];

const Year = () => {
  const [data,setData] = useState([]);
  useEffect(()=>{
    ApiService.getAllUsers().then((res)=> setData(res.data.data))
  })
  const rows = data.map((row, index) => ({
    ...row,
    id: row._id,
    fullName: row.first_name + " " + row.lastName,
    loginTime: row.loginTime,
    rowIndex: index + 1,
  }));
  return (
    <div className="year">
      <Box sx={{ height: "800px", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{
          Pagination: CustomPagination,
        }}
        />
      </Box>
    </div>
  );
};

export default Year;
