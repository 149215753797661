import React, { useState, useEffect } from "react";
// import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "./feedBack.css";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import ApiService from "../../services/ApiService";


const FeedBack = () => {
  const user = localStorage.getItem("token");
  // checking if user is logged in or not and redirecting to login page if not logged in and trying to access other pages except login page and dashboard page
  if (!user) {
    window.location = "/login";
  }
  const [setError] = useState("");
  const [feedback, setFeedBack] = useState([]);


  useEffect(() => {
    getProduct();
  });

  const getProduct = async () => {
    try {
      ApiService.getAllFeedback().then((res) => {
        // console.log(res.data.data);
        setFeedBack(res.data.data);
      });
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
  };

  // var now = new Date();
  // var daysOfYear = [];
  // for (var d = new Date(2022, 0, 1); d <= now; d.setDate(d.getDate() + 1)) {
  //     daysOfYear.push(new Date(d));
  // }
  // const val = new Date(moment().format('D MMM, YYYY'))
  // console.log(new Date('2022-06-06T17:26:22.225+00:00'));

  const columns = [
    {
      field:"rowIndex",
      headerName:"S.No",
      width:100,
      sortable:true,
    },
    {
      field: "user_name",
      headerName: "User Name",
      width: 160,
    },
    { field: "email", headerName: "Email", width: 180 },
    {
      field: "rating",
      headerName: "Rating",
      renderCell: (params) => (
        <>
          {Array(+params.row.rating).fill(
            <AiFillStar size={20} color={"yellow"} />
          )}
          {Array(5 - params.row.rating).fill(
            <AiOutlineStar size={20} color={"black"} />
          )}
        </>
      ),
    },
    {
      field: "feedback",
      headerName: "Feedback",
      sortable: false,
      width: 180,
    },
  ];

  const rows = feedback.map((row,index) => ({
    ...row,
    id: row._id,
    feedback: row.feedback,
    rowIndex:index+1,
  }));
  return (
    <div className="feedBack">
      <Box sx={{ height: "650px", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </div>
  );
};

export default FeedBack;
