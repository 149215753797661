import React, { useState,useEffect } from "react";
import ApiService from "../../services/ApiService";
import Box from '@mui/material/Box';
import {   DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector, } from '@mui/x-data-grid';
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import './today.css'

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const columns = [
  {
    field:"rowIndex",
    headerName:"S.No",
    width:100,
    sortable:true,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    width: 160,
    valueGetter: (params) =>
      `${params.row.first_name || ''} ${params.row.last_name || ''}`,
  },
  { field: 'email', headerName: 'Email', width: 180 },
  { field: 'loginTime', headerName: 'Login Time', width: 180 },
];

const Today = () => {
  const [data,setData] = useState([]);
  useEffect(()=>{
    ApiService.getAllUsers().then((res) => {
      console.log(res.data.data)
      const data = res.data.data;
      const today = new Date();
      const today_date = today.toISOString().split('T')[0];
      const user = data.filter(item => item.loginDate == today_date)
      console.log(user)
      setData(user)
    })
  },[])
  let yourDate = new Date();
  console.log(yourDate)

  const today_date = yourDate.toISOString().split('T')[0];
  console.log(today_date);
  const user = data;
  const rows = user.map((row,index) => ({
    ...row,
    id: row._id,
    fullName: row.first_name + ' ' + row.lastName,
    loginTime: row.loginTime,
    rowIndex: index + 1,
    }));


  return (
    <div className="today">
    {user.length>0?(
      <Box sx={{ height: "700px", width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Pagination: CustomPagination,
        }}
      />
    </Box>):(
      <div className="no-data">
        <h1>Todays Login is Empty</h1>
        </div>)}
    </div>
  )
}

export default Today