import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import "./month.css";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const columns = [
  {
    field:"rowIndex",
    headerName:"S.No",
    width: 100,
    sortable: true,
  },
  {
    field: "fullName",
    headerName: "Full name",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.first_name || ""} ${params.row.last_name || ""}`,
  },
  { field: "email", headerName: "Email", width: 180 },
  { field: "loginTime", headerName: "Login Time", width: 180 },
  { field: "loginDate", headerName: "Login Date", width: 180 },
];

const Month = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    ApiService.getAllUsers().then((res) => setData(res.data.data));
  });
  const month = new Date().getMonth() + 1;
  const monthData = data.filter(
    (item) => item.loginDate[6] === month.toString()
  );
  console.log(monthData);
  const rows = monthData.map((row, index) => ({
    ...row,
    id: row._id,
    fullName: row.first_name + " " + row.lastName,
    loginTime: row.loginTime,
    rowIndex: index+1,
  }));

  const getMonthData = (startDate,endDate) => {

    console.log(endDate.toISOString().split('T')[0]);
        
        // for(let i=startDate;1<=endDate;i++){
        //   console.log(i)
        // }
  }

  return (
    <div className="month">
      <div className="searchMonth">
        <div className="search">
          <div className="filed">
           <p>From</p>
            <DatePicker
              selected={startDate}
              onChange={(sdate: Date) => setStartDate(sdate)}
            />
          </div>
          <div className="filed">
          <p>To</p>
          <DatePicker
            selected={endDate}
            onChange={(edate: Date) => setEndDate(edate)}
          />
          </div>
          <button className="button" onClick={() => getMonthData(startDate,endDate)}>Search</button>
        </div>
      </div>
      <Box sx={{ height: "800px", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Pagination: CustomPagination,
          }}
        />
      </Box>
    </div>
  );
};

export default Month;
