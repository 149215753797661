import { hot } from "react-hot-loader/root";
import { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import Header from "./components/header/Header";
import Users from "./pages/user/Users";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import Today from "./pages/today/Today";
import Month from "./pages/month/Month";
import Year from "./pages/year/Year";
import TodaysRooms from "./pages/rooms/TodaysRooms";
import FeedBack from "./pages/feedBack/FeedBack";
import YearsMeet from "./pages/rooms/YearsMeet";
import Login from "./components/login/Login";
import UpdateUser from "./components/header/updateUser/UpdateUser";
function App() {
  const user = localStorage.getItem("token");

  return (
    <>
      <Router>
        <Header />
        <Header />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="/users" element={<Users />} />
          <Route path="/today_logins" element={<Today />} />
          <Route path="/month_logins" element={<Month />} />
          <Route path="/year_logins" element={<Year />} />
          <Route path="/todays_meets" element={<TodaysRooms />} />
          <Route path="/year_meets" element={<YearsMeet />} />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="/update" element={<UpdateUser />} />
        </Routes>
      </Router>
    </>
  );
}

export default hot(App);
