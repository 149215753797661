import React, { useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import "./Header.css";

const TopBar = () => {
  // if user is logged in, show logout button
  // else show login button
  const user = localStorage.getItem("token");
  // get the the button element and add event listener to it
  const handleSubmit = (e) => {
    e.preventDefault();
    const text = document.querySelector("button").textContent;
    if (text === "Logout") {
      localStorage.removeItem("token");
      window.location = "/login";
    }
  };

  return (
    <>
      <header>
      <Link to="/"><img
      src="./images/meta-unite-final.png"
      style={{ height: "6rem", color: "white" }}
      alt="/"
    /></Link>
        

        <div className="profile">
        <Link to={"/update"}><BiUserCircle style={{ fontSize: "1.5rem" }} /></Link>
          
          <Link to="/login">
            <button onClick={handleSubmit}>{user ? "Logout" : "Login"}</button>
          </Link>
        </div>
      </header>
    </>
  );
};

export default TopBar;
