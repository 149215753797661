import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./rooms.css";
import Box from "@mui/material/Box";
import { MdDeleteOutline } from "react-icons/md";
import ApiService from "../../services/ApiService";
import axios from "axios";

const TodaysRooms = () => {
  const [room, setRoom] = useState([]);
  const today_date = new Date()
    .toLocaleString(undefined, { timeZone: "Asia/Kolkata" })
    .slice(0, 9);

  useEffect(() => {
    ApiService.getAllRoom().then((res) => setRoom(res.data.room));
  });
  const timeDiffer = (rowData) => {
    // calculate difference between createdTime and endTime
    const createdTime = rowData.meetTime;
    if(!rowData.endTime){
      return {hour:"0", minutes:"0"};
    }
    // const endTime = rowData.endTime;
    const endTimeSplit =  rowData.endTime? rowData.endTime.split(" ")[1] : "00:00";
    const hours = endTimeSplit.split(":")[0];
    const minutes = endTimeSplit.split(":")[1];

    const hoursCreated = createdTime.split(":")[0];
    const minutesCreated = createdTime.split(":")[1];

    const hoursDiff = hours - hoursCreated;
    const minutesDiff = minutes - minutesCreated;
    var m, h;
    if (minutesDiff < 0) {
      m = 60 + minutesDiff;
      h = hoursDiff - 1;
    }
    if (hoursDiff < 0) {
      h = 24 + hoursDiff;
    }

    const timeDiff = {
      hours: h,
      minutes: m,
    };
    return timeDiff;
  };
  const rooms = room.filter((item) => item.meetDate === today_date);
  const handleDelete = async (id) => {
    setRoom(rooms.filter((item) => item._id !== id));
    const url = `https://metaunite.onrender.com/room/delete_room/${id}`;
    await axios.post(url);
  };

  const columns = [
    {
      field: "rowIndex",
      headerName: "S.No",
      width: 100,
      sortable: true,
    },
    {
      field: "name",
      headerName: "CreatedBy",
      width: 160,
      valueGetter: (params) => params.row.createdBy,
    },
    {
      field: "meet_id",
      headerName: "MeetId",
      width: 180,
      valueGetter: (params) => params.row.meet_Id,
    },
    {
      field: "created_time",
      headerName: "CreatedTime",
      type: "time",
      width: 190,
      editable: true,
      valueGetter: (params) => params.row.meetTime,
    },
    {
      field: "endTime",
      headerName: "Duration",
      type: "String",
      width: 150,
      valueGetter: (params) =>
        params.row.endTime.hours > 0
          ? params.row.endTime.hours + " hrs "
          : " " + params.row.endTime.minutes + " mins",
    },
    {
      field: "participants",
      headerName: "Participants",
      width: 190,
      editable: true,
      valueGetter: (params) => params.row.participants.length,
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <MdDeleteOutline
              className="roomListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  const rows = rooms.map((row, index) => ({
    ...row,
    id: row._id,
    rowIndex: index + 1,
    endTime: timeDiffer(row),
    // fullName: row.first_name + ' ' + row.lastName,
    // status: row.userLogout === true? 0 :row.isBlocked === true? 1 : 3
  }));
  return (
    <div className="rooms">
      {rooms.length > 0 ? (
        <Box sx={{ height: "600px", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      ) : (
        <div className="no-data">
          <h1>Todays Room is Empty</h1>
        </div>
      )}
    </div>
  );
};

export default TodaysRooms;
