import React from "react";
import "./featuredInfo.css";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { GoPrimitiveDot } from "react-icons/go";

const FeaturedInfo = ({
  data,
  count1,
  inactiveUser,
  logoutUser,
  blockedUser,
}) => {
  return (
    <div className="featured">
      <div className="featuredItem">
      <div>
        <span className="featuredTitle">Total Users</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{data.length}</span>
          <span className="featuredMoneyRate">
            -11.4 <ArrowDownward className="featuredIcon negative" />
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
        </div>
      </div>
      <div className="featuredItem">
        <div>
        <span className="featuredTitle">Active Users</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{count1}</span>
          <span className="featuredMoneyRate">
            -1.4 <ArrowDownward className="featuredIcon negative" />
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
        </div>
      </div>
      <div className="featuredItem">
        <div>
          <span className="featuredTitle">Inactive Users</span>
          <div className="featuredMoneyContainer">
            <span className="featuredMoney">
              { 
                logoutUser.length + blockedUser.length}
            </span>
            <span className="featuredMoneyRate">
              +2.4 <ArrowUpward className="featuredIcon" />
            </span>
          </div>
          <span className="featuredSub">Compared to last month</span>
        </div>
        <div>
        <div style={{alineItem:"center",display:"flex",justifyContent:"center"}}><GoPrimitiveDot style={{fontSize:"30px",}}/> <h2>{logoutUser.length}</h2></div>
          <div style={{alineItem:"center",display:"flex",justifyContent:"center"}}><GoPrimitiveDot style={{color:"red",fontSize:"30px",}}/> <h2>{blockedUser.length}</h2></div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedInfo;
