import React from "react";
import "./chart.css";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Chart = ({ title, dataKey, grid,data }) => {
  const mon = ["","Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
  const currentMonth = new Date().getMonth()+1
  const userData = [];
  for(let i =0;i<=currentMonth;i++){
    let month = new Date("2022-01-01").getMonth()+i;
    let monthData = data.filter( (item) => item.createdAt[6] === month.toString());
    userData.push({
      name:mon[i],
      "Active User":monthData.length
    })
  }

  return (
    <div className="chart">
      <h3 className="chartTitle">{title}</h3>
      <ResponsiveContainer width="100%" paddingTop='1rem' aspect={4 / 1}>
        <LineChart data={userData}>
          <XAxis dataKey="name" stroke="#8884d8" />
          <Line type="monotone" dataKey={dataKey} stroke="#5550bd" />
          <Tooltip />
          {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
