import React, { useState, useEffect } from "react";
import "./users.css";
import { Box, Modal, Typography } from "@mui/material";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { GoPrimitiveDot } from "react-icons/go";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ApiService from "../../services/ApiService";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const Users = () => {
  const user = localStorage.getItem("token");
  // checking if user is logged in or not and redirecting to login page if not logged in and trying to access other pages except login page and dashboard page
  if (!user) {
    window.location = "/login";
  }
  const [data, setData] = useState([]);
  const [open, setOpen] = useState("");
  const handleOpen = (id) => setOpen(id);
  const handleOpen1 = (id) => setOpen(id);
  const handleClose = () => setOpen("");
  const handleClose1 = () => setOpen("");

  const blockUser = async (id) => {
    ApiService.userBlock(id).then((res) => {
      setOpen("");
    });
  };
  const buttonStyle = {
    backgroundColor: "red",
    color: "white",
    border: "none",
    width: "100px",
    borderRadius: "5px",
    padding: "5px",
    cursor: "pointer",
    margin: "5px",
  };

  const unblockUser = async (id) => {
    // const url = `https://logsign.herokuapp.com/user/unblock_user/${id}`;
    // await axios.post(url);
    // console.log(id, "unblocked");
    ApiService.userUnBlock(id).then((res) => {
      setOpen("");
    });
  };

  useEffect(() => {
    ApiService.getAllUsers().then((res) => {
      // sort the data by createdAt
      const sortedData = res.data.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setData(sortedData);
    });
  });
  const columns = [
    {
      field: "rowIndex",
      headerName: "S.No.",
      width: 70,
    //  set the serial no of the row in the table
      description: "This column has a value getter and is not sortable.",
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 90,
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <GoPrimitiveDot
              style={{
                color: params.row.userLogout
                  ? "black"
                  : params.row.isBlocked
                  ? "red"
                  : "#3bb077",
                fontSize: "25px",
              }}
            />
          </>
        );
      },
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 160,
      valueGetter: (params) =>
        `${
          params.row.first_name
            ? params.row.first_name.toString().trim()
            : "" || ""
        } ${
          params.row.last_name
            ? params.row.last_name.toString().trim()
            : "" || ""
        }`,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 210,
      valueGetter: (params) =>
        `${params.row.email ? params.row.email.toString().trim() : "" || ""}`,
    },
    {
      field: "mobile_number",
      headerName: "Mobile number",
      width: 140,
      // valueGetter: (params) => `${params.row.mobile_number || ""}`,
    },
    {
      field: "join_date",
      headerName: "Registered On",
      type: "date",
      width: 190,
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <div>
              <button
                className="productListDelete"
                onClick={() => handleOpen1(params.row._id)}
              >
                Block
              </button>
              <Modal
                open={open === params.row._id}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Are you sure to block{" "}
                    <b> {params.row.first_name + " " + params.row.last_name}</b>{" "}
                    ?
                  </Typography>
                  <div>
                    <button
                      onClick={() => blockUser(params.row._id)}
                      style={buttonStyle}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => handleClose1(params.row._id)}
                      style={buttonStyle}
                    >
                      No
                    </button>
                  </div>
                </Box>
              </Modal>
            </div>
          </>
        );
      },
    },
    {
      field: "unblock",
      width: 100,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <button
              className="productListEdit"
              onClick={() => unblockUser(params.row._id)}
            >
              UnBlock
            </button>
            {/* <div>
              <button
                className="productListEdit"
                onClick={() => handleOpen(params.row._id)}
              >
                Unblock
              </button>
              <Modal
                open={open === params.row._id}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Are you sure to unblock{" "}
                    <b> {params.row.first_name + " " + params.row.last_name}</b>{" "}
                    ?
                  </Typography>
                  <div>
                    <button
                      onClick={() => unblockUser(params.row._id)}
                      style={buttonStyle}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => handleClose(params.row._id)}
                      style={buttonStyle}
                    >
                      No
                    </button>
                  </div>
                </Box>
              </Modal>
            </div> */}
          </>
        );
      },
    },
  ];

  const join_date = data.map((item, index) => item.createdAt)

  const join_date_new = join_date
    .map((item) => {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      const date = new Date(item);
      const year = date.getFullYear();
      // Array of months starts from 0
      const month = months[date.getMonth()];

      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();


      const newDate = `${day}-${month}-${year} - ${hours}:${minutes}:${seconds}`;
      return newDate ;
    })
   
  const rows = data.map((row, index) => ({
    ...row,
    id: row._id,
    join_date: join_date_new[index],
    rowIndex: index+1,
  }
  
  ));
  return (
    <div className="users">
      <Box sx={{ height: "800px", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Pagination: CustomPagination,
          }}
        />
      </Box>
    </div>
  );
};

export default Users;
