import axios from "axios";

export default () =>{
    return axios.create({
      baseURL: "https://metaunite.onrender.com",
      headers: {
        "Content-Type": "aplication/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
}