import React, { useState } from "react";
import ApiService from "../../services/ApiService";
import "./login.css";
import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // If email and password are empty the function will return and not send the request to the server
    if (!email) {
      // focus on the email input
      document.querySelector("input[name='email']").focus();
      // change the color of the border of the input to red
      document.querySelector(".email").style.color = "red";
      return;
    }
    if (!password) {
      // focus on the password input
      document.querySelector("input[name='password']").focus();
      document.querySelector(".password").style.color = "red";
      return;
    }

    try {
      const res = await axios.post(
        "https://metaunite.onrender.com/admin/login",
        {
          email,
          password,
        }
      );
      localStorage.setItem("token", res.data.token);
      window.location = "/";
    } catch (error) {
      setError(error.response.data);
      if (
        error.response.status === 400 ||
        error.response.status === 404 ||
        error.response.status === 401
      ) {
        setError(error.response.data);
      }
    }
  };

  return (
    <div className="body">
      <div className="box">
        <div className="form">
          <h2>Sign In</h2>
          <p
            style={{
              color: "red",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            {error ? error.message : ""}
          </p>
          <div className="inputBox">
            <input
              name="email"
              type="text"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <span className="email">Email</span>
            <i></i>
          </div>
          <div className="inputBox">
            <input
              name="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <span className="password">Password</span>
            <i></i>
          </div>
          <div className="button" onClick={handleSubmit}>
            Login
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
